<template>
    <el-card>
        <el-empty description="功能开发中......"></el-empty>
    </el-card>
</template>

<script>
export default {
  name: 'PrivacyDesign',
}
</script>

<style scoped>

</style>
